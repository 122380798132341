<template>
    <div class="col-12 pl-2 pr-2 h-100">
        <b-table
            :items="getItems"
            :fields="fields"
            :current-page="currentPage"
            :per-page="selectPerPage"
            stacked="md"
            show-empty
            small
            striped
            id="AppContactTable"
            thead-tr-class="m-table"
            ref="contactTable"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @sort-changed="updateSort"
            sort-icon-left
        >
            <b-tr class="d-md-none"></b-tr>
            <template #cell(selection)="row" :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}">

                <span v-if="row.item.status === 'ENABLED'">
                    <input
                        type="checkbox"
                        v-model="row.item.selection"
                        :checked="selectRow"/>
                </span>

                <span v-else>
                    <input type="checkbox" v-model="row.item.selection" disabled/>
                </span>
            </template>

            <template #cell(person.lastName)="row">
                {{ row.item.title }}
            </template>

            <template #cell(person.email)="row">
                {{ row.item.email }}
            </template>

            <template #cell(course)="row">
                <div class="d-flex" v-if="row.item.course">
                    <!--  {{row.item.status}} -->
<!--                    <span v-for="(code, index) in row.item.contactsCourses" :key="code.id">-->
<!--                        <div v-if="index <= 0">-->
                            <small>
<!--                                {{ typeCourseById(code.idCourse) }}-->
                                {{ typeCourseById(row.item.course) }}
                            </small>
<!--                        </div>-->
<!--                    </span>-->
                </div>
            </template>

            <template #cell(activity)="row">
                <div class="d-flex" v-if="row.item.activityIds">
                    <div class="d-flex" v-if="row.item.activityIds.length > 0">
<!--                        orderActivitiesContacts(row.item.contactsActivities)-->
                        <span :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}" v-for="(code, index) in row.item.activityIds" :key="code.id">
                            <div v-if="index <= 2">
                                <b-badge class="m-badge ml-1" :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}">
                                    <span>
                                    {{ activityTypeCodeById(code) }}
                                    </span>
                                </b-badge>
                            </div>
                        </span>
                    </div>

                    <div class="d-flex ml-1" v-if="row.item.activityIds && row.item.activityIds.length > 3">
                        <b-badge class="m-badge">
                            <span>+{{ row.item.activityIds.length - 3 }}</span>
                        </b-badge>
                    </div>
                </div>
            </template>

            <template #cell(lastActivity)="row">
                <span v-if="row.item.lastActivity">
                    {{ LastActivityNameById(row.item.lastActivity) }}
                </span>

                <span v-if="row.item.notes !== ''">
                    <hr class="mt-0 mb-0" />
                    <small><b v-html="row.item.note"></b></small>
                </span>

            </template>

            <template #cell(privacy)="row" :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}">
                <div class="d-flex justify-content-center">
                    <span v-if="row.item.isPrivacy == true">
                        <b-icon
                        :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}"
                        icon="check-square"
                        scale="2"
                        font-scale="0.7"
                        variant="success"
                        ></b-icon>
                    </span>
                    <span v-else>
                        <b-icon
                        :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}"
                        icon="x-square"
                        scale="2"
                        font-scale="0.7"
                        variant="danger"
                        ></b-icon>
                    </span>
                </div>
            </template>

            <template #cell(newsletter)="row">
                <div class="d-flex justify-content-center">

                    <span v-if="row.item.isNewsletter == true">
                        <b-icon
                            :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}"
                            icon="check-square"
                            scale="2"
                            font-scale="0.7"
                            variant="success"
                        ></b-icon>
                    </span>
                    <span v-else>
                        <b-icon
                            :class="{ 'text-black-50' : row.item.status !== 'ENABLED'}"
                            icon="x-square"
                            scale="2"
                            font-scale="0.7"
                            variant="danger"
                        ></b-icon>
                    </span>
                </div>
            </template>

            <template #cell(actions)="row">
                <span v-if="row.item.status === 'ENABLED'">
                    <b-button
                        size="sm"
                        :to="{
                            name: 'DettagliContatto',
                            query: { id: row.item.id },
                        }"
                        class="mr-1"
                    >
                        Dettagli
                    </b-button>
                </span>
                <span v-else>
                    Archiviato
                </span>
            </template>
        </b-table>

        <!--    Current page:  {{currentPage}} / {{selectPerPage}}-->
        <b-pagination
            v-model="currentPage"
            :total-rows="getRow"
            size="sm"
            :per-page="selectPerPage"
            aria-controls="AppContactTable"
            align="center"
            @input="handlePaginatorClick"
        ></b-pagination>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    props: ["getItems", "getRow", "changePage", "refresh", "selectRow", "selectPerPage", "currentPage"],
    name: "AppContactsTable",
    data() {
        return {
            fields: [
                {
                    key: "selection",
                    label: "",
                    sortable: false,
                    thClass: "",
                    tdClass: "d-flex justify-content-start ",
                },
                {
                    key: "title",
                    label: "Nome Cognome",
                    sortable: true,
                },
                {
                    key: "email",
                    label: "Email",
                    sortable: true,
                },
                {
                    key: "tel",
                    label: "Numero",
                    sortable: false,
                },
                {
                    key: "course",
                    label: "Corso interesse",
                    sortable: true,
                },
                {
                    key: "activity",
                    label: "Tipologia Attività",
                    sortable: false,
                },
                {
                    key: "lastActivity",
                    label: "Ultima Attività | Nota",
                    sortable: false,
                },
                {
                    key: "privacy",
                    label: "Privacy",
                    sortable: false,
                },
                {
                    key: "newsletter",
                    label: "Newsletter",
                    sortable: false,
                },
                {
                    key: "actions",
                    label: "",
                    sortable: false,
                    thClass: "",
                    tdClass: "d-flex justify-content-end ",
                },
            ],

            sortBy: "Ord",
            sortDesc: false,
            totalRows: 1,
        };
    },
    created() {
        this.syncSort()
    },

    methods: {
        ...mapActions(["contact/getContactsPage", "contact/archiveSingleContacts"]),

        handlePaginatorClick(pageNumber) {
        // console.log('handlePaginatorClick', pageNumber);
            if (!isNaN(pageNumber)) {
                localStorage.setItem("contactsCurrentPage", pageNumber)
            }
        },

        syncSort() {
          if (localStorage.getItem("contactsCurrentSort")) {
              let sort = JSON.parse(localStorage.getItem("contactsCurrentSort"));
              this.sortBy = sort.sortBy;
              this.sortDesc = sort.sortDesc;
          }
        },

        typeCourseById($id) {
        const courseName = this.$store.getters["courses/typeCourseById"]($id);

        let name = 0;
        if (courseName !== undefined) {
        name = courseName.name;
        } else {
        name = 0;
        }
        return name;
        /* return courseName; */
        },

        LastActivityNameById($lastActivityId) {
            // let activitiesArray = $array;
            // console.log(activitiesArray);
            // const data = [];
            // activitiesArray.forEach((element) => {
            //     data.push({
            //         idActivity: element.idActivity,
            //         // eventDt: new Date(element.eventDt).getTime(),
            //         // note: element.note,
            //     });
            // });

            // function compare(a, b) {
            //     if (
            //         a == undefined ||
            //         b == undefined ||
            //         a.eventDt == undefined ||
            //         b.eventDt == undefined
            //     ) {
            //         return 0;
            //     }
            //     if (new Date(b.eventDt).getTime() < new Date(a.eventDt).getTime()) {
            //         return -1;
            //     }
            //     if (new Date(a.eventDt).getTime() > new Date(b.eventDt).getTime()) {
            //         return 1;
            //     }
            //     return 0;
            // }
            // const sortActivities = data.sort(compare);

            // const activitiesId = sortActivities[0].idActivity;
            // const activityName = this.$store.getters["activity/activityById"](
            //     activitiesId
            // );

            // const activitiesId = $lastActivityId;
            const activityName = this.$store.getters["activity/activityById"](
                $lastActivityId
            );

            // console.log(activitiesId, activityName);

            let name = '';
            if (activityName !== undefined) {
                name = activityName.activity.name;
            } else {
                name = 0;
            }

            return name;
        },

        orderActivitiesContacts($array) {
          let activitiesArray = $array;
          const data = [];
          activitiesArray.forEach((element) => {
              data.push({
                  idActivity: element.idActivity,
                  eventDt: new Date(element.eventDt).getTime(),
              });
          });

          function compare(a, b) {
              if (
                  a == undefined ||
                  b == undefined ||
                  a.eventDt == undefined ||
                  b.eventDt == undefined
              ) {
                  return 0;
              }
              if (new Date(b.eventDt).getTime() < new Date(a.eventDt).getTime()) {
                  return -1;
              }
              if (new Date(a.eventDt).getTime() > new Date(b.eventDt).getTime()) {
                  return 1;
              }
              return 0;
          }

          return data.sort(compare);
        },

        activitiesContactsById($id) {
          const activity = this.$store.getters["contact/activitiesContactsById"](
              $id
          );
          return activity;
        },

        activityTypeCodeById($id) {
          const activityType_Code = this.$store.getters["activity/activityById"](
              $id
          );
          /* const activity = this.$store.getters["activity/activity"]; */
          /*  console.log(activity); */
          let code = 0;
          /*  console.log(activityType_Code); */
          if (
              activityType_Code !== undefined &&
              activityType_Code.activity !== undefined
          ) {
              code = activityType_Code.activity.activityType_Code;
          } else {
              code = 0;
          }
          return code;

          /* return activityType_Code; */
        },

        async archiveRow($id) {
          /*  console.log($id); */
          try {
              await this.$store
                  .dispatch("contact/archiveSingleContacts", {
                      idContact: $id,
                  })
                  .then(() => {
                      this.contact = this.$store.getters["contact/contact"];

                      return this.contact;
                  });
          } catch (e) {
              console.log({message: "Some error archiveSingleContacts"});
          }
        },

        updateSort(e) {
            console.log(this.sortBy);

            this.sortBy = e.sortBy;
            this.sortDesc = e.sortDesc;

            let sort = {
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
            };

            localStorage.setItem("contactsCurrentSort", JSON.stringify(sort));
        },
    },
};
</script>
